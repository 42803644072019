import React, { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import PackageCategoriesDrList from "./PackageCategoriesDrList";

interface Pkg {
  pkg: any;
  onClick: any;
}
const PackageCard: React.FC<Pkg> = ({ pkg, onClick }) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
      <div
        className="card custom-card-new h-100 border-0 shadow w-100"
        style={{ borderRadius: "0px" }}
      >
        <div className="d-flex align-items-start p-3">
          <img
            src={pkg.image}
            className="me-2"
            alt={pkg.title}
            style={{
              width: "120px",
              height: "120px",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
          <div>
            <h5 className="text-uppercase fw-bold mb-1 custom-font-style custom-font-style-v1 mb-0 text-custom-black">
              {pkg.title}
            </h5>
            <p className="mb-0 text-muted custom-font-style custom-font-style-v1 custom-black-color">
              {pkg.description}
            </p>
          </div>
        </div>
        <div className="container pt-0 pb-0 pb-3">
          <hr className="mt-0 mb-0"></hr>
        </div>
        <div className="card-body d-flex flex-column pt-0">
          <p className="text-dark text-start py-0 letter_spacing custom-font-style text-custom-black">
            {pkg?.category?.length} CATEGORIES - UNDER THIS PACKAGE
          </p>
          <div className="d-flex justify-content-start mb-0 gap-3 pb-3 mobile-flex-wrap-v1">
            {pkg?.category?.map((category: any, index: any) => (
              <span
                key={index}
                className="letter_spacing  px-2 py-1  custom-batch text-custom-black"
              >
                {category?.name}
              </span>
            ))}
          </div>
          <button
            className="btn mt-auto w-100 letter_spacing custom-border-btn mt-0 text-custom-black"
            style={{ border: "1px solid black", borderRadius: "0px" }}
            onClick={onClick}
          >
            SELECT PACKAGE
          </button>
        </div>
      </div>
    </div>
  );
};

interface PackageList {
  packageListBroadCategoryWise: any;
  broadCategoryList: any;
  broadCategoryId: any;
}
const PackageSelection: React.FC<PackageList> = ({
  packageListBroadCategoryWise,
  broadCategoryList,
  broadCategoryId,
}) => {
  const [appointmentDuration, selectAppointmentDuration] = useState<any>("");
  const [appointmentMode, selectAppointmentMode] = useState<any>("");
  const [doctorList, setDoctorList] = useState<any>([]);
  const [drId, setFirstDrId] = useState<any>("");
  const [broadCatId, setBroadCatId] = useState<any>("");
  const [closeMainModal, setCloseMainModal] = useState<any>(false);
  const [activeCategoryUuid, setActiveCategoryUuid] = useState<any>(0);
  const [activeCategoryId, setActiveCategoryId] = useState<any>(0);
  const [highlightRemainingCatId, setHighlightReminaingId] = useState<any>([]);
  const [infoMode, setInfoMode] = useState<any>(false);
  const [infoDurationMode, setInfoDurationMode] = useState<any>(false);
  const [packageUuid, setPackageUuid] = useState<any>(0);
  const [selectedTotalCategoryIds, setSelectedTotalCategoryIds] = useState<any>(
    []
  );
  const handleCloseModalChange = (value: boolean) => {
    setCloseMainModal(value); // Update the state when child component modifies it
  };

  useEffect(() => {
    if (highlightRemainingCatId?.length > 0) {
      showDoctorList(
        highlightRemainingCatId && highlightRemainingCatId[0]?.uuid,
        ""
      );
    }
  }, [highlightRemainingCatId?.length]);

  const handleRemainingCartId = (value: any) => {
    setHighlightReminaingId(value);
  };

  const handleSelectedCategoryIds = (value: any) => {
    setSelectedTotalCategoryIds(value);
  };
  const categoriesUnderPackage: any =
    packageListBroadCategoryWise && packageListBroadCategoryWise[0]?.category;

  const activeCatId =
    categoriesUnderPackage && categoriesUnderPackage[0]?.categoryid;

  const durationType = (duration: any) => {
    setInfoDurationMode(false);
    selectAppointmentDuration(duration);
  };

  useEffect(() => {
    if (drId) {
      showDoctorList(drId, activeCatId);
    }
  }, [drId]);

  const showDoctorList = (uuid: any, id: any) => {
    const selectedCategory = categoriesUnderPackage.find(
      (category: any) => category.uuid === uuid
    );
    const ID: any = selectedCategory?.categoryid;

    setActiveCategoryUuid(uuid);
    setActiveCategoryId(ID);
    axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 10,
            filters: [
              {
                field: "isActive",
                operator: "eq",
                value: 1,
              },
              {
                field: "uuid",
                operator: "eq",
                value: uuid,
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "categoryLists",
            appName: "selfMaximized",
            folder: "category",
          },
          {
            agent: "categoryDoctorList",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setDoctorList(result?.data?.data);
        } else {
          setDoctorList([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };
  const modeOfAppointment = (mode: any) => {
    selectAppointmentMode(mode);
    const firstCategoryDoctor: any =
      categoriesUnderPackage && categoriesUnderPackage[0]?.uuid;
    setFirstDrId(firstCategoryDoctor);
  };

  const openModal = (id: any, packageUuid: any) => {
    setBroadCatId(id);
    setPackageUuid(packageUuid);
    const modalElement = document.getElementById("selectPackageModalOpen");
    if (modalElement) {
      const modal = new Modal(modalElement); // Create a new Modal instance
      modal.show(); // Show the modal
    } else {
      console.error("Modal element not found");
    }
  };

  const ProgressBar = ({ current, total }: any) => {
    const progress = (current / total) * 100;

    return (
      <div
        style={{
          width: "50%",
          height: "25px",
          backgroundColor: "#000",
          borderRadius: "50px",
          border: "1px solid #fff",
          marginBottom: "20px",
          position: "relative",
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            height: "100%",
            backgroundColor: "#fff",
            borderRadius: "5px",
          }}
        ></div>

        <div
          style={{
            position: "absolute",
            top: "155%",
            letterSpacing: "1px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          {Math.round(progress)}%
        </div>
      </div>
    );
  };

  console.log(selectedTotalCategoryIds + "selectedIds");
  return (
    <>
      <div className="container-fluid left-right-space mt-7">
        <div className="">
          <h4 className="text-left text-white fw-bold mb-0 custom-font-style">
            SELECT A PACKAGE BEFORE WE PROCEED
          </h4>
          <p className="text-white mb-4 text-left custom-font-style">
            SAVE 10% ON ANY PACKAGE YOU SELECT
          </p>
          <div className="row justify-content-start">
            {packageListBroadCategoryWise.map((elem: any, index: any) => (
              <PackageCard
                key={elem.id}
                pkg={elem}
                onClick={() => openModal(elem.broad_category_id, elem.uuid)}
              />
            ))}
          </div>
        </div>
      </div>

      <div
        className="modal  p-0 gloabl-model-settings"
        id="selectPackageModalOpen"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-full modal-xl modal-dialog-centered">
          <div className="modal-content user_response--popup user_response--popup-v1 p-2 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center my-0 py-0 ">
              <h3 className="text-white">
                {selectedTotalCategoryIds} /{" "}
                {categoriesUnderPackage && categoriesUnderPackage.length}{" "}
                <span className="letter_spacing ps-2">CATEGORIES SELECTED</span>
              </h3>
              {categoriesUnderPackage &&
                categoriesUnderPackage.length !== 0 && (
                  <ProgressBar
                    current={selectedTotalCategoryIds}
                    total={categoriesUnderPackage.length}
                  />
                )}

              <ul
                className="nav nav-tabs custom-common-tabs border-0 justify-content-center pb-4 mt-4"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link nav-link-custom active`}
                    id="home-tab"
                    data-bs-toggle="tab"
                    href="#home"
                    role="tab"
                    style={{
                      display: "none",
                    }}
                    aria-controls="home"
                    aria-selected="false"
                  >
                    1
                  </a>
                  <span
                    className="text-uppercase text-ceter text-white custom-font-style custom-font-style-v1 "
                    style={{
                      display: "none",
                    }}
                  >
                    Appointment duration
                  </span>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link "
                    id="profile-tab"
                    data-bs-toggle="tab"
                    style={{
                      display: "none",
                    }}
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="true"
                  >
                    2
                  </a>
                  <span
                    className="text-uppercase text-ceter text-white custom-font-style custom-font-style-v1"
                    style={{
                      display: "none",
                    }}
                  >
                    SELECT SPECIALIST
                  </span>
                </li>
              </ul>

              <div
                className="tab-content col-md-12 col-lg-12"
                id="myTabContent"
              >
                <div
                  className="tab-pane active show tab-w-636"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <p className="text-uppercase h4 text-white text-start custom-font-style custom-font-style-v1 pt-3 mt-lg-5 mb-lg-2">
                    Appointment type -{" "}
                  </p>
                  <p className=" text-white text-uppercase text-start custom-font-style custom-font-style-v1">
                    Select appointment type before we proceed further
                  </p>
                  <div className="appointment-duration-box mt-lg-5">
                    <p
                      className={` text-${
                        !appointmentDuration && infoDurationMode === true
                          ? "danger"
                          : "white"
                      } text-uppercase text-start custom-font-style custom-font-style-v1`}
                    >
                      Select Duration of your appointment
                    </p>
                    <div className="appointment-duration-box-inner">
                      <span
                        className="appointment-custom-box custom-font-style custom-font-style-v1 text-uppercase text-uppercase"
                        onClick={() => durationType("30")}
                        style={{
                          color:
                            appointmentDuration === "30" ? "black" : "white",
                          backgroundColor:
                            appointmentDuration === "30" ? "white" : "black",
                        }}
                      >
                        30 minute virtual appointment
                      </span>
                      <span
                        className="appointment-custom-box custom-font-style custom-font-style-v1 text-uppercase text-uppercase"
                        onClick={() => durationType("60")}
                        style={{
                          color:
                            appointmentDuration === "60" ? "black" : "white",
                          backgroundColor:
                            appointmentDuration === "60" ? "white" : "black",
                        }}
                      >
                        60 minute virtual appointment
                      </span>
                    </div>
                  </div>

                  <div className="appointment-duration-box pt-4">
                    <p
                      className={`text-${
                        !appointmentMode && infoMode === true
                          ? "danger"
                          : "white"
                      } text-uppercase text-start custom-font-style custom-font-style-v1`}
                    >
                      Select mode of your appointment
                    </p>
                    <div className="appointment-duration-box-inner">
                      <span
                        className="appointment-custom-box custom-font-style custom-font-style-v1 text-uppercase"
                        onClick={() => modeOfAppointment("video")}
                        style={{
                          color:
                            appointmentMode === "video" ? "black" : "white",
                          backgroundColor:
                            appointmentMode === "video" ? "white" : "black",
                        }}
                      >
                        <i
                          className={`fa-solid fa-video text-${
                            appointmentMode === "video" ? "dark" : "white"
                          }`}
                        ></i>{" "}
                        video
                      </span>
                      <span
                        className="appointment-custom-box custom-font-style custom-font-style-v1 text-uppercase"
                        onClick={() => modeOfAppointment("audio")}
                        style={{
                          color:
                            appointmentMode === "audio" ? "black" : "white",
                          backgroundColor:
                            appointmentMode === "audio" ? "white" : "black",
                        }}
                      >
                        <i
                          className={`fa-solid fa-headphones text-${
                            appointmentMode === "audio" ? "dark" : "white"
                          }`}
                        ></i>
                        Audio
                      </span>
                      <span
                        className="appointment-custom-box custom-font-style custom-font-style-v1 text-uppercase"
                        onClick={() => modeOfAppointment("chat")}
                        style={{
                          color: appointmentMode === "chat" ? "black" : "white",
                          backgroundColor:
                            appointmentMode === "chat" ? "white" : "black",
                        }}
                      >
                        <i
                          className={`fa-solid fa-message ext-${
                            appointmentMode === "chat" ? "dark" : "white"
                          }`}
                        ></i>
                        Chat
                      </span>
                    </div>
                  </div>

                  <div className="next-custom-link text-end pt-3">
                    <a
                      href="#"
                      onClick={() => {
                        if (!appointmentMode || !appointmentDuration) {
                          setInfoMode(true);
                          setInfoDurationMode(true);
                          return;
                        } else {
                          const getId = document.getElementById("profile-tab");
                          if (getId) {
                            getId.click();
                          }
                        }
                      }}
                      className="text-decoration-underline text-white h3 text-uppercase font-weight-400 ustom-font-style custom-font-style-v1"
                    >
                      Next
                    </a>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div
                    className="blog_messonry_button d-flex justify-content-between align-items-center custom-tab-width"
                    id="jornalTab"
                  >
                    <div
                      className="position-relative"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <div className="tabs-left_arrow">
                        <button
                          className=""
                          style={{ cursor: "pointer", color: "grey" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            fill="currentColor"
                            className="bi bi-arrow-left"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.707 4.707a1 1 0 0 1 0 1.414L8.414 8l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0z" />
                          </svg>
                        </button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          overflowX: "auto",
                          scrollBehavior: "smooth",
                          scrollbarWidth: "none",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {categoriesUnderPackage &&
                        categoriesUnderPackage.length !== 0 &&
                        categoriesUnderPackage !== null
                          ? categoriesUnderPackage.map((elem: any) => (
                              <button
                                className={`${
                                  activeCategoryUuid === elem?.uuid ||
                                  activeCategoryId === elem?.categoryid
                                    ? "active"
                                    : ""
                                } text-uppercase`}
                                style={{
                                  padding: "5px 10px",
                                  whiteSpace: "nowrap",
                                }}
                                key={elem.uuid}
                                onClick={() => {
                                  showDoctorList(elem.uuid, elem.categoryid);
                                }}
                              >
                                {elem.name}
                              </button>
                            ))
                          : null}
                      </div>
                      <div className="tabs-right_arrow">
                        <button style={{ cursor: "pointer", color: "grey" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            fill="currentColor"
                            className="bi bi-arrow-right"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.293 12.293a1 1 0 0 1 0-1.414L9.586 8 6.293 4.707a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <PackageCategoriesDrList
                      doctorList={doctorList}
                      packageUuid={packageUuid}
                      appointmentDuration={appointmentDuration}
                      categoriesUnderPackage={categoriesUnderPackage}
                      activeCategoryUuid={activeCategoryUuid}
                      activeCategoryId={activeCategoryId}
                      closeMainModal={closeMainModal}
                      onCloseMainModalChange={handleCloseModalChange}
                      highlightRemainingCatId={highlightRemainingCatId}
                      handleRemainingCartId={handleRemainingCartId}
                      selectedTotalCategoryIds={selectedTotalCategoryIds}
                      handleSelectedCategoryIds={handleSelectedCategoryIds}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              data-bs-dismiss="modal"
              className="custom-dismiss-model"
              id="dismissMainModal"
            >
              <i className="fa-solid fa-xmark text-white"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageSelection;
