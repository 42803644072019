import React, { useContext, useEffect } from "react";
import AuthContext from "../../../UseContext";

const SuccessPage: React.FC = () => {
  const authContext = useContext(AuthContext);
  useEffect(() => {
    authContext.cart(0);
    localStorage.clear();
  }, []);

  const goToDashboard = () => {
    window.location.href = "/app/#/dashboard";
  };

  return (
    <div className="success-container">
      <div className="success-content position-relative p-5">
        <div>
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            {" "}
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />{" "}
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <h2 className="success-title mt-3 mb-0">Payment Successful!</h2>
        <p className="success-message mb-0">
          Thank you for your purchase. Your order has been successfully
          processed.
        </p>
        <button
          className="btn btn-light mt-2 go__to--dashboard"
          onClick={goToDashboard}
        >
          GO TO DASHBOARD
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;
