import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import Select from "react-select";
import {
  format,
  addDays,
  startOfWeek,
  endOfWeek,
  isSameWeek,
  endOfMonth,
} from "date-fns";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment-timezone";
import { toast } from "react-toastify";

interface BookSpecialistUuid {
  doctorUuid: any;
}

// interface Slot {
//   uuid: string;
//   calendarDay: string;
//   isBooked: number;
//   // Add other properties as needed
// }

interface Slot {
  uuid: string;
  calendarDate: string;
  doctorId: number;
  StartTime: string;
  EndTime: string;
  Duration: string;
  calendarDay: string;
  isBooked: number;
  dname: string;
}

interface GroupedSlots {
  [day: string]: Slot[];
}
const BookSpecialist: React.FC<BookSpecialistUuid> = ({ doctorUuid }) => {
  const { id } = useParams();
  const [productData, setProductData] = useState<any>([]);
  const [specialistData, setSpecialistData] = useState<any>([]);
  const [productDuration, setProductDuration] = useState<any>("");
  const [slots, setSlots] = useState<any>([]);
  const [selectedSlot, setSelectedSlot] = useState<any>("");
  const [ipAddress, setIpAddress] = useState<any>("");
  const [cartId, setCartId] = useState<any>("");
  const [productId, setProductUuid] = useState<any>("");
  const [productKeyId, setProductId] = useState<any>("");
  const [subscriptionProductId, setSubscriptionProductId] = useState<any>("");
  const today = new Date();
  const [startDate, setStartDate] = useState<any>(startOfWeek(today));
  const [endDate, setEndDate] = useState<any>(endOfMonth(today));
  const [rangeSelected, setRangeSelected] = useState<any>(false);
  const [dateRange, setSelectedRange] = useState<any>("");
  const [selectedMonth, setSelectedMonth] = useState<any>(
    format(today, "MMMM")
  );
  const [modeType, setModeType] = useState<any>("");
  const [isChecked, setIsChecked] = useState<any>(false);
  const location = useLocation();
  const [bookStatus, setBookStatus] = useState<any>("bookNow");
  const [warningStatus, setWarningStatus] = useState<any>(false);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [timeZones, setTimeZones] = useState<any>([]);
  const [selectedTimezone, setSelectedTimeZone] = useState<any>("");

  const categoryId = location.pathname.split("/");
  let data = localStorage.getItem("userInfo");
  let userInfo = null;
  if (typeof data === "string") {
    try {
      userInfo = JSON.parse(data);
    } catch (error) {
      console.error("Error parsing userInfo:", error);
    }
  } else {
    userInfo = data;
  }

  useEffect(() => {
    getGlobalTimeZones();
  }, []);
  let lastActiveCartId: any = userInfo ? userInfo.lastActiveCart : null;

  const handleReviewModal = () => {
    let reviewModal: any = document.getElementById("reviewModal");
    if (reviewModal) {
      reviewModal.click();
    }
  };

  const getUserRating = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        doctorId: specialistData.id,
        categoryId: categoryId[2],
        action: "command",
        command: [
          {
            agent: "userStarPercentageList",
            appName: "selfMaximized",
            folder: "users",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          console.log(result?.data?.data);
        }
      });
  };
  const handleReviewModal2 = async () => {
    await getUserRating();
    let reviewModal2: any = document.getElementById("reviewModal2");
    if (reviewModal2) {
      reviewModal2.click();
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      const dismissModal = document.getElementById("dismissModal");
      if (dismissModal) {
        dismissModal.click();
      }
      const dismissFirstModal = document.getElementById("closeFirstModal");
      if (dismissFirstModal) {
        dismissFirstModal.click();
      }
    };

    // Listen for the popstate event
    window.addEventListener("popstate", handlePopState);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handlBookSlotModal = async () => {
    if (!productKeyId) {
      return setWarningStatus(true);
    }
    if (!modeType) {
      return setWarningStatus(true);
    }
    let bookSlotModal: any = document.getElementById("bookSlotModal");
    if (bookSlotModal) {
      bookSlotModal.click();
      window.history.pushState(null, "", window.location.href);
      let currentTz: any = "";

      const response = await axios.get("https://ipapi.co/json/");
      if (response?.data && response?.data?.timezone) {
        currentTz = {
          value: response?.data?.timezone,
          label: response?.data?.timezone,
        };
      }

      setSelectedTimeZone(currentTz);
      getSpecialistBookingSlots(startDate, endDate);
    }
  };

  useEffect(() => {
    if (doctorUuid.length === 1) {
      const openModal = () => {
        let modal: any = document.getElementById("popUpModal");
        if (modal) {
          modal.click();
          window.history.pushState(null, "", window.location.href);
        }
      };
      openModal();

      categoryWiseSpecialistProducts();
      getSpecialistDetailsById();
    }
  }, [doctorUuid, id]);

  async function categoryWiseSpecialistProducts() {
    if (doctorUuid.length && id) {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            doctorId: doctorUuid.length !== 0 ? doctorUuid[0] : 0,
            categoryUuid: id,
            isActive: 1,
            action: "command",
            command: [
              {
                agent: "productBySpecCategory",
                appName: "selfMaximized",
                folder: "specialist",
              },
            ],
          }
        );
        if (result?.data?.statusCode === 200) {
          setProductData(result?.data?.data);
        } else {
          setProductData([]);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function getSpecialistDetailsById() {
    try {
      const result = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 1,
            filters: [
              {
                field: "id",
                operator: "eq",
                value: doctorUuid,
              },
            ],
            sort: [
              {
                field: "id",
                table: "Users",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "specialistListsData",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      });

      if (result?.data?.statusCode === 200) {
        setSpecialistData(result?.data?.data[0]);
      } else {
        setSpecialistData([]);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const selectProduct = (appointmentDurationType: any) => {
    setProductDuration(appointmentDurationType.duration);
    setProductUuid(appointmentDurationType.uuid);
    setProductId(appointmentDurationType.id);
  };

  const selectSubscriptionProduct = (appointmentDurationType: any) => {
    setSubscriptionProductId(appointmentDurationType.uuid);
  };

  const showExpData = (type: string) => {
    const language =
      specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
        ? specialistData?.DoctorExperiences[0].language
        : "";
    const areaOfExpertise =
      specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
        ? specialistData?.DoctorExperiences[0].areaofexpertise
        : "";
    const title =
      specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
        ? specialistData?.DoctorExperiences[0].title
        : "";
    let parsedLanguage: any;
    let parsedAreaOfExp: any;

    if (language && areaOfExpertise) {
      parsedLanguage = JSON.parse(language);
      parsedAreaOfExp = JSON.parse(areaOfExpertise);
    }

    if (parsedLanguage && type === "language") {
      return parsedLanguage.join("|");
    }
    if (parsedAreaOfExp && type === "areaofexpertise") {
      return parsedAreaOfExp.map((elem: any) => elem.category).join(" | ");
    }
    return title;
  };

  const handleRangeSelection = async (selectedRange: any) => {
    if (selectedRange) {
      setRangeSelected(true);
      setSelectedRange(selectedRange);
    }
    if (Array.isArray(selectedRange) && selectedRange.length === 2) {
      const [startDate, endDate] = selectedRange;
      setStartDate(startDate);
      setEndDate(endDate);
      await getSpecialistBookingSlots(startDate, endDate);
    } else {
      console.error("Invalid date range selected");
    }
  };

  useEffect(() => {
    setSlots([]);
    if (dateRange) {
      setRangeSelected(true);
    }
    if (Array.isArray(dateRange) && dateRange.length === 2) {
      const [startDate, endDate] = dateRange;
      setStartDate(startDate);
      setEndDate(endDate);
      setTimeout(() => {
        getSpecialistBookingSlots(startDate, endDate);
      }, 2000);
    } else if (startDate || endDate) {
      getSpecialistBookingSlots(startDate, endDate);
    }
  }, [selectedTimezone?.value]);

  // const getSpecialistBookingSlots = async (
  //   newStartDate?: any,
  //   newEndDate?: any
  // ) => {
  //   try {
  //     const result = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
  //       filter: {
  //         filter: {
  //           logic: "and",
  //           offset: 0,
  //           limit: 500,
  //           filters: [
  //             {
  //               field: "calendarDate",
  //               operator: "between",
  //               table: "BookingCalendar",
  //               value: moment(newStartDate || startDate).format("YYYY-MM-DD"),
  //               opr: "AND",
  //               value2: moment(newEndDate || endDate).format("YYYY-MM-DD"),
  //             },
  //             {
  //               field: "doctorId",
  //               operator: "eq",
  //               table: "BookingCalendar",
  //               value: specialistData?.id,
  //             },
  //             {
  //               field: "Duration",
  //               operator: "eq",
  //               table: "BookingCalendar",
  //               value: parseFloat(productDuration).toFixed(2),
  //             },
  //           ],
  //           sort: [
  //             {
  //               field: "calendarDate",
  //               table: "BookingCalendar",
  //               dir: "asc",
  //             },
  //           ],
  //         },
  //       },
  //       action: "command",
  //       command: [
  //         {
  //           agent: "specialistCalendarBooking",
  //           appName: "selfMaximized",
  //           folder: "order",
  //         },
  //       ],
  //     });

  //     if (result?.data?.code === 1) {
  //       const responseData: Slot[] = result?.data?.data?.response || [];

  //       const groupedSlots: GroupedSlots = responseData.reduce(
  //         (acc: GroupedSlots, slot: Slot) => {
  //           acc[slot.calendarDay] = [...(acc[slot.calendarDay] || []), slot];
  //           return acc;
  //         },
  //         {}
  //       );

  //       const userTimezonee = selectedTimezone?.value
  //         ? selectedTimezone?.value
  //         : Intl.DateTimeFormat().resolvedOptions().timeZone;

  //       const today = moment.tz(userTimezonee).startOf("day");

  //       // Filtered slots to include from the start of the week to the end of the week, showing only future slots within the current week from today onward
  //       const filteredSlotsArray = Object.entries(groupedSlots)
  //         .map(([day, slots]) => {
  //           const filteredSlots = slots.filter((slot) => {
  //             const slotDate = moment.tz(
  //               `${slot.calendarDate}`,
  //               "YYYY-MM-DD",
  //               userTimezonee
  //             );

  //             // Only include slots within the selected date range
  //             return (
  //               slot.isBooked === 0 &&
  //               slotDate.isSameOrAfter(newStartDate, "day") &&
  //               slotDate.isSameOrBefore(newEndDate, "day")
  //             );
  //           });

  //           return {
  //             dayOfWeek: day,
  //             slots: filteredSlots,
  //           };
  //         })
  //         .filter((daySlots) => daySlots.slots.length > 0);

  //       const filteredSlotsFromToday = filteredSlotsArray
  //         .map((daySlot) => {
  //           const slotsFromToday = daySlot.slots.filter((slot) => {
  //             const slotDate = moment.tz(
  //               `${slot.calendarDate}`,
  //               "YYYY-MM-DD",
  //               userTimezonee
  //             );
  //             return slotDate.isSameOrAfter(today, "day");
  //           });

  //           return {
  //             ...daySlot,
  //             slots: slotsFromToday,
  //           };
  //         })
  //         .filter((daySlot) => daySlot.slots.length > 0); // Only include days with slots from today onward

  //       setSlots(filteredSlotsFromToday);
  //     } else {
  //       toast.error("Something went wrong");
  //     }
  //   } catch (err) {
  //     toast.error("Something went wrong");
  //   }
  // };

  const getSpecialistBookingSlots = async (
    newStartDate?: any,
    newEndDate?: any
  ) => {
    try {
      // Ensure the selected timezone is being used, fallback to system timezone if not available
      const userTimezonee = selectedTimezone?.value || moment.tz.guess();

      // Format start and end dates to the correct timezone
      const formattedStartDate = moment
        .tz(newStartDate || startDate, userTimezonee)
        .format("YYYY-MM-DD");
      const formattedEndDate = moment
        .tz(newEndDate || endDate, userTimezonee)
        .format("YYYY-MM-DD");

      const result = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "calendarDate",
                operator: "between",
                table: "BookingCalendar",
                value: formattedStartDate,
                opr: "AND",
                value2: formattedEndDate,
              },
              {
                field: "doctorId",
                operator: "eq",
                table: "BookingCalendar",
                value: specialistData?.id,
              },
              {
                field: "isBooked",
                operator: "eq",
                table: "BookingCalendar",
                value: 0,
              },
              {
                field: "active",
                operator: "eq",
                table: "BookingCalendar",
                value: 1,
              },
              {
                field: "Duration",
                operator: "eq",
                table: "BookingCalendar",
                value: parseFloat(productDuration).toFixed(2),
              },
            ],
            sort: [
              {
                field: "calendarDate",
                table: "BookingCalendar",
                dir: "asc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "specialistCalendarBooking",
            appName: "selfMaximized",
            folder: "order",
          },
        ],
      });

      if (result?.data?.code === 1) {
        const responseData: Slot[] = result?.data?.data?.response || [];

        // Get the current date and time in the selected timezone
        const now = moment.tz(selectedTimezone?.value || userTimezone);

        // Helper function to preprocess StartTime and convert to localized datetime
        const getSlotDateTime = (startTime: string): moment.Moment => {
          return moment
            .tz(
              startTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
              "YYYY-MM-DDTHH:mm:ssZ", // Parse format
              "UTC"
            )
            .tz(selectedTimezone?.value || userTimezone);
        };

        // Filter out past dates and times
        const filteredSlots = responseData.filter((slot) => {
          const slotDateTime = getSlotDateTime(slot.StartTime);

          // Include only slots in the future
          return slot.isBooked === 0 && slotDateTime.isAfter(now);
        });

        // Group slots by their localized calendarDate
        const groupedSlots: GroupedSlots = filteredSlots.reduce(
          (acc: GroupedSlots, slot: Slot) => {
            const slotDate = getSlotDateTime(slot.StartTime).format(
              "YYYY-MM-DD"
            );
            acc[slotDate] = [...(acc[slotDate] || []), slot];
            return acc;
          },
          {}
        );

        // Organize slots into days with their corresponding slots
        const filteredSlotsArray = Object.entries(groupedSlots)
          .map(([day, slots]: [string, Slot[]]) => ({
            dayOfWeek: moment(day, "YYYY-MM-DD")
              .tz(selectedTimezone?.value || userTimezone)
              .format("dddd"), // Convert to day of the week
            slots,
          }))
          .filter((daySlots) => daySlots.slots.length > 0); // Only include days with available slots

        setSlots(filteredSlotsArray);
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const passSlots = (selectedSlot: any) => {
    setSelectedSlot(selectedSlot);
  };

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setIpAddress(data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  const createCart = async () => {
    let data = localStorage.getItem("userInfo");
    let userInfo = null;
    if (typeof data === "string") {
      try {
        userInfo = JSON.parse(data);
      } catch (error) {
        console.error("Error parsing userInfo:", error);
      }
    } else {
      userInfo = data;
    }
    let lastActiveCartId: any = userInfo ? userInfo.lastActiveCart : null;
    const cartId = localStorage.getItem("cartId");
    if (lastActiveCartId || cartId) {
      await addToCart();
    } else {
      if (!selectedSlot && bookStatus === "bookNow") {
        return toast.info("PLEASE SELECT SLOT FIRST", {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="60"
              height="60"
              viewBox="0 0 30 30"
            >
              <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
            </svg>
          ),
          className: "custom-toast",
          bodyClassName: "custom-toast",
        });
      }
      axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          ip: ipAddress,
          action: "command",
          command: [
            {
              agent: "createCart",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        })
        .then(async (result) => {
          if (result?.data?.statusCode === 200) {
            setCartId(result?.data?.data?.cartId);
            localStorage.setItem("cartId", result?.data?.data?.cartId);
            await addToCart();
          }
        });
    }
  };

  const addToCart = async () => {
    const cartId = localStorage.getItem("cartId");
    const cartListData = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        cartUuid:
          lastActiveCartId !== null && lastActiveCartId !== undefined
            ? lastActiveCartId
            : cartId,
        organizationId: 1,
        action: "command",
        command: [
          {
            agent: "cartList",
            appName: "selfMaximized",
            folder: "order",
          },
        ],
      }
    );

    const refinedCartList = cartListData?.data?.data;

    const dismissModal = document.getElementById("dismissModal");
    if (dismissModal) {
      dismissModal.click();
    }
    if (!selectedSlot && bookStatus === "bookNow") {
      return toast.info("PLEASE SELECT SLOT FIRST", {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="60"
            height="60"
            viewBox="0 0 30 30"
          >
            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
          </svg>
        ),
        className: "custom-toast",
        bodyClassName: "custom-toast",
      });
    }

    // Check if the slot already exists in the cart and show a toast if it does
    const isSlotAlreadySelected =
      refinedCartList.length !== 0 &&
      refinedCartList.some((elem: any) => {
        if (
          elem.start_time === selectedSlot.StartTime &&
          elem.end_time === selectedSlot.EndTime &&
          elem.appointment_day === selectedSlot.calendarDay &&
          elem.appointment_date === selectedSlot.calendarDate
        ) {
          toast.info("THIS TIME SLOT FOR SELECTED DAY IS ALREADY SELECTED", {
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="60"
                height="60"
                viewBox="0 0 30 30"
              >
                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
              </svg>
            ),
            className: "custom-toast",
            bodyClassName: "custom-toast",
          });
          return true;
        }
        return false;
      });

    if (!isSlotAlreadySelected) {
      // If the slot is not already selected, proceed to add to cart
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          cartUuid:
            lastActiveCartId !== null && lastActiveCartId !== undefined
              ? lastActiveCartId
              : cartId,
          productId: productId,
          ip: ipAddress,
          qty: 1,
          organizationId: 1,
          metadata: [
            {
              productId: productKeyId ? productKeyId : null,
              doctorId: specialistData ? specialistData.id : null,
              StartTime:
                bookStatus === "bookLater" ? null : selectedSlot.StartTime,
              EndTime: bookStatus === "bookLater" ? null : selectedSlot.EndTime,
              Duration: productDuration,
              Day: bookStatus === "bookLater" ? null : selectedSlot.calendarDay,
              Date:
                bookStatus === "bookLater" ? null : selectedSlot.calendarDate,
              categoryid: categoryId ? categoryId[2] : null,
              modeType: modeType,
              timezone: selectedTimezone?.value,
              meta_type: "singular",
            },
          ],
          appointment: [
            {
              appnt_type: "singular",
              chat_mode: modeType,
              doctorId: specialistData ? specialistData.id : null,
              book_status: bookStatus === "bookLater" ? 0 : 1,
              start_time:
                bookStatus === "bookLater" ? null : selectedSlot.StartTime,
              end_time:
                bookStatus === "bookLater" ? null : selectedSlot.EndTime,
              appointment_day:
                bookStatus === "bookLater" ? null : selectedSlot.calendarDay,
              appointment_date:
                bookStatus === "bookLater" ? null : selectedSlot.calendarDate,
              start_date: null,
              end_date: null,
              timezone: selectedTimezone?.value,
              admin_discount: 0,
              plan_per_month: 1,
            },
          ],
          action: "command",
          command: [
            {
              agent: "addToCart",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            window.location.href = "/cart";
          } else {
            toast.info(result?.data?.message, {
              icon: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="60"
                  height="60"
                  viewBox="0 0 30 30"
                >
                  <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
                </svg>
              ),
              className: "custom-toast text-uppercase",
              bodyClassName: "custom-toast",
            });
          }
        })
        .catch((err) => {
          toast.error("Something went wrong", err);
        });
    }
  };
  const handleMonthChange = ({ activeStartDate }: any) => {
    const monthName = format(activeStartDate, "MMMM"); // Get the month name
    setSelectedMonth(monthName);
  };

  const handleModeType = (type: any) => {
    setModeType(type);
  };

  const getGlobalTimeZones = async () => {
    const timezones: any = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        action: "command",
        command: [
          {
            agent: "timezoneList",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      }
    );
    const response: any = await timezones?.data?.data;
    setTimeZones(response);
  };

  const mappedOptions =
    timeZones &&
    timeZones
      .sort((a: any, b: any) => a?.timezone.localeCompare(b?.timezone))
      .map((option: any) => ({
        value: option?.timezone,
        label: option?.timezone,
      }));

  const handleTimeZone = (selectedOption: any) => {
    setSelectedTimeZone(selectedOption);
  };

  return (
    <div>
      <input
        className="next-btn"
        type="hidden"
        id="popUpModal"
        data-bs-toggle="modal"
        data-bs-target="#specialistDetails"
      />
      <input
        className="next-btn"
        type="hidden"
        id="bookSlotModal"
        data-bs-toggle="modal"
        data-bs-target="#bookSlotPopUp"
      />
      <input
        className="next-btn"
        type="hidden"
        id="reviewModal"
        data-bs-toggle="modal"
        data-bs-target="#reviewModalOpen"
      />

      <input
        className="next-btn"
        type="hidden"
        id="reviewModal2"
        data-bs-toggle="modal"
        data-bs-target="#reviewModalOpen2"
      />

      <section
        className="pb-0 user-registration-flow-wrapper py-0"
        id="bigPopUp"
      >
        <div className="container-fluid left-right-space extra-space-container">
          <div className="row mt-5" id="modalPopUp">
            <div
              className="modal fade user__pop__wrapper p-0 "
              id="specialistDetails"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-fullscreen transform-none transition-none modal-dialog-centered">
                <div className="modal-content py-5 position-relative specialist-pop-up subscribe-popup">
                  <div className="container-fluid container-fluid-custom">
                    <div className="row pb-2 mobile-flex-wrap">
                      <div className="col max-width-225 height-219">
                        <div className="specialist-image specialist-image-box">
                          <img
                            src={specialistData?.profile}
                            alt=""
                            className="img-fluid rounded"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="subscription-group subscription-group-new">
                          <ul className="ps-0">
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Name <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase font-size-24">
                                    {specialistData?.name}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Gender <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    {specialistData?.gender}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    PROFESSIONAL <span></span> TITLE{" "}
                                    <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    {specialistData &&
                                    specialistData?.DoctorExperiences &&
                                    specialistData?.DoctorExperiences.length
                                      ? specialistData?.DoctorExperiences[0]
                                          .title
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Area of Expertise{" "}
                                    <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase font-size-20">
                                    {showExpData("areaofexpertise")}
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    BIO <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Bio info
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 text-uppercase">
                                    Experience <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="text-white mb-0 text-uppercase explore-heading-one mb-0 text-uppercase">
                                    {specialistData.length !== 0 &&
                                      specialistData?.DoctorExperiences[0]
                                        .yearofexperience}{" "}
                                    YEARS EXPERIENCE
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="subscription-group-inner">
                                <div className="subscription-part-one">
                                  <p className="explore-heading-one text-white mb-0 mb-0-custom text-uppercase">
                                    Language <span className="colon">:</span>
                                  </p>
                                </div>
                                <div className="subscription-part-two">
                                  <p className="explore-heading-one text-white mb-0 mb-0-custom text-uppercase">
                                    {showExpData("language")}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="subscription-group">
                        <ul className="ps-0">
                          <li className="star-group star-group-outer">
                            <div className="ratting-outer-box">
                              <span className="explore-heading-one text-white d-inline pe-2 vertical-center">
                                3.5
                              </span>
                              <Rating
                                size={30}
                                // onClick={(value: any) => {
                                //   checkFilter(elem, value);
                                //   setIntialVal(value);
                                // }}
                                fillColor="white"
                                emptyColor="black"
                                SVGstorkeWidth="1px"
                                SVGstrokeColor="white"
                                allowFraction
                                style={{
                                  marginBottom: "0px",
                                  paddingBottom: "0px",
                                }}
                                // initialValue={initialVal}
                                className="stat-rating"
                              />
                              <span className="explore-heading-one text-white d-inline vertical-center">
                                <a
                                  onClick={handleReviewModal2}
                                  className="text-uppercase text-white d-inline explore-heading-one text-decoration-underline cursor-pointer ps-2 text-decoration-none"
                                >
                                  (18)
                                </a>
                              </span>
                            </div>
                            {/* <div className="review-outer">
                              <a
                                onClick={handleReviewModal}
                                className="text-uppercase text-white d-inline explore-heading-one text-decoration-underline cursor-pointer text-decoration-none"
                              >
                                Write reviews
                              </a>
                            </div> */}
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* <div className="row pb-4">
                        <div className="col max-width-250">
                          <div className="specialist-image specialist-image-box">
                            <img
                              src={specialistData?.profile}
                              alt=""
                              className="img-fluid rounded"
                            />
                          </div>
                        </div>
                        <div className="col">
                              <div className="subscription-group">
                                    
                            
                                <h3 className="explore-heading-one text-white mb-0 text-uppercase">
                                  {specialistData?.name}
                                </h3>
                                <p className="mb-0 specialist-gender text-uppercase">
                                  {specialistData?.gender}
                                </p>
                                <p className="mb-0 specialist-expert text-uppercase">
                                  {showExpData("title")}
                                </p>
                                <p className="mb-0 specialst_degree text-uppercase">
                                  {showExpData("areaofexpertise")}
                                </p>

                                <p className="specialist-year-of-experience mb-0 text-uppercase">
                                  {specialistData.length !== 0 &&
                                    specialistData?.DoctorExperiences[0]
                                      .yearofexperience}{" "}
                                  YEARS EXPERIENCE
                                </p>
                                <p className="mb-0 specialist-langauge pb-2 text-uppercase">
                                  {showExpData("language")}
                                </p>

                                </div>
                        </div>
                      </div> */}
                    <hr className="text-white mt-0 custom-hr"></hr>

                    <div className="row">
                      <div className="col-lg-12 please-select-text">
                        <h2
                          className={`${
                            !productKeyId && warningStatus
                              ? "text-danger"
                              : "text-white"
                          } text-uppercase mb-0 mb-0`}
                        >
                          please select appointment type
                        </h2>
                        <p className="text-white p explore-heading-one text-uppercase">
                          Please select all fields before proceeding
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="appointment-type-wrapper appointment-type-wrapper-box">
                          <div className="row gap-5">
                            <div
                              className={`col singular-appointment-wrapper singular-appointment-wrapper-new ${"active"}`}
                            >
                              <div className="mb-3 form-check form-check-custom">
                                <input
                                  className="form-check-input  custom-checkbox custom-checkbox-new mb-1"
                                  type="checkbox"
                                  style={{
                                    width: "16px", // Adjust the width as needed
                                    height: "16px", // Adjust the height as needed
                                    outline: "none",
                                    borderRadius: "0", // Ensure it stays square
                                  }}
                                  id="exampleCheck1"
                                  onClick={() => {
                                    setIsChecked(true);
                                  }}
                                  checked={isChecked || productId}
                                />
                                <label
                                  className="form-check-label mb-0"
                                  htmlFor="exampleCheck1"
                                >
                                  SINGULAR APPOINTMENT OPTIONS :
                                </label>
                                <p className="explore-heading-one text-white text-uppercase">
                                  valid for 30 days
                                </p>
                              </div>
                              <div className="px-md-4">
                                {productData.length !== 0 &&
                                productData !== null
                                  ? productData.map((elem: any) => (
                                      <>
                                        <div
                                          className={`d-md-flex justify-content-between align-items-center py-2 px-3 mb-2 cursor_pointer`}
                                          onClick={() => selectProduct(elem)}
                                          key={elem.id}
                                          style={{
                                            border: `${
                                              productId !== elem.uuid
                                                ? "1px solid white"
                                                : ""
                                            }`,
                                            backgroundColor: `${
                                              productId === elem.uuid
                                                ? "white"
                                                : ""
                                            }`,
                                          }}
                                        >
                                          <div className="">
                                            <p
                                              className="mb-0 appointment-text-one"
                                              style={{
                                                color: `${
                                                  productId !== elem.uuid
                                                    ? "white"
                                                    : ""
                                                }`,
                                              }}
                                            >
                                              {parseFloat(
                                                elem.duration
                                              ).toFixed(2) === "1.00"
                                                ? 60
                                                : 30}
                                              - MINUTE VIRTUAL APPOINTMENT{" "}
                                            </p>
                                          </div>
                                          <div className="">
                                            <div className="package-amount-in-dollar-one px-3 py-1">
                                              <div className="d-flex align-items-center">
                                                <span
                                                  className="pe-1"
                                                  style={{
                                                    color:
                                                      productId !== elem.uuid
                                                        ? "white"
                                                        : "",
                                                  }}
                                                >
                                                  <i className="fa-solid fa-dollar-sign"></i>
                                                </span>
                                                <span
                                                  className="amount-dollar_package-one"
                                                  style={{
                                                    color:
                                                      productId !== elem.uuid
                                                        ? "white"
                                                        : "",
                                                  }}
                                                >
                                                  {elem.price}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ))
                                  : null}

                                {productData.length !== 0 &&
                                productData !== null ? (
                                  <>
                                    <div className="py-3">
                                      <p
                                        className={`${
                                          !modeType && warningStatus
                                            ? "text-danger"
                                            : "text-light"
                                        } mb-0 font-18 please-select-mode`}
                                      >
                                        PLEASE SELECT MODE OF APPOINTMENT
                                      </p>
                                    </div>
                                    <div className="appointment-duration-box-inner mb-3">
                                      <span
                                        className="appointment-custom-box custom-font-style custom-font-style-v1 text-uppercase"
                                        onClick={() => handleModeType("video")}
                                        style={{
                                          color:
                                            modeType === "video"
                                              ? "black"
                                              : "white",
                                          backgroundColor:
                                            modeType === "video"
                                              ? "white"
                                              : "black",
                                        }}
                                      >
                                        <i
                                          className={`fa-solid fa-video text-${
                                            modeType === "video"
                                              ? "dark"
                                              : "white"
                                          }`}
                                        ></i>{" "}
                                        video
                                      </span>
                                      <span
                                        className="appointment-custom-box custom-font-style custom-font-style-v1 text-uppercase"
                                        onClick={() => handleModeType("audio")}
                                        style={{
                                          color:
                                            modeType === "audio"
                                              ? "black"
                                              : "white",
                                          backgroundColor:
                                            modeType === "audio"
                                              ? "white"
                                              : "black",
                                        }}
                                      >
                                        <i
                                          className={`fa-solid fa-headphones text-${
                                            modeType === "audio"
                                              ? "dark"
                                              : "white"
                                          }`}
                                        ></i>
                                        Audio
                                      </span>
                                      <span
                                        className="appointment-custom-box custom-font-style custom-font-style-v1 text-uppercase"
                                        onClick={() => handleModeType("chat")}
                                        style={{
                                          color:
                                            modeType === "chat"
                                              ? "black"
                                              : "white",
                                          backgroundColor:
                                            modeType === "chat"
                                              ? "white"
                                              : "black",
                                        }}
                                      >
                                        <i
                                          className={`fa-solid fa-message ext-${
                                            modeType === "chat"
                                              ? "dark"
                                              : "white"
                                          }`}
                                        ></i>
                                        Chat
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <span className="text-white letter_spacing ">
                                    NO PRODUCTS AVAILABLE
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col singular-appointment-wrapper singular-appointment-wrapper-new text-center">
                              <h3 className="text-white letter_spacing d-flex justify-content-center">
                                SUBSCRIPTION IN PROGRESS
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 ps-3 pe-3 text-end mt-0 mt-md-5 mt-lg-5 mt-xl-5 book-slot-outer">
                        <div className="px-md-5">
                          <button
                            className="btn book-btn book-btn-new min-height-40"
                            type="button"
                            onClick={handlBookSlotModal}
                          >
                            BOOK SLOT
                          </button>
                          {/* <button
                            className="btn book-btn book-btn-new min-height-40"
                            type="button"
                          >
                            ADD TO CART
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="cancel__pop__icon"
                    data-bs-dismiss="modal"
                    id="closeFirstModal"
                  >
                    <i className="fa-solid fa-xmark text-white bg-dark"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal  p-0 gloabl-model-settings"
        id="reviewModalOpen"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex={-1}
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content user_response--popup p-2 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center my-0 py-0">
              <div className="customer-reviews w-100">
                <ul className="d-flex  ps-0 justify-content-between">
                  <li className="d-flex align-items-center">
                    <h6 className="text-white text-uppercase mb-0 explore-heading-two h5">
                      Customer review
                    </h6>
                  </li>
                  <li className="star-group star-group-outer d-flex align-items-center gap-3">
                    <div className="ratting-outer-box ratting-outer-box-review">
                      <span className="explore-heading-one explore-heading-one text-white d-flex align-items-center pe-2">
                        4.6
                      </span>
                      <Rating
                        size={30}
                        fillColor="white"
                        emptyColor="black"
                        SVGstorkeWidth="1px"
                        SVGstrokeColor="white"
                        allowFraction
                        style={{
                          marginBottom: "0px",
                          paddingBottom: "0px",
                        }}
                        // initialValue={initialVal}
                        className="stat-rating"
                      />
                      <span className="explore-heading-one text-white d-inline text-uppercase d-flex align-items-center ps-2 ">
                        4.5 OUT OF 5
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <hr className="custom-white-hr w-100 mt-0" />
              <div className="w-100 d-block">
                <label className="text-white text-start explore-heading-two w-100">
                  28,172 GLOBAL RATING
                </label>
                <textarea
                  className="form-control w-100 custom-height customer-review-textarea custom-placeholder"
                  id=""
                  rows={3}
                  defaultValue={""}
                  placeholder="TYPE HERE"
                />
              </div>
              <div className="submit-btn-custom w-100 d-flex justify-content-end">
                <button
                  className="btn book-btn book-btn-new d-block mb-0 mt-4 min-height-40"
                  data-bs-target="#specialistDetails"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  SUBMIT
                </button>
              </div>
            </div>
            <div
              className="cancel__pop__icon"
              data-bs-target="#specialistDetails"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              <i className="fa-solid fa-xmark text-white"></i>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal  p-0 gloabl-model-settings"
        id="reviewModalOpen2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel3"
        tabIndex={-1}
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content user_response--popup p-2 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center my-0 py-0">
              <div className="customer-reviews w-100">
                <ul className="d-flex  ps-0 justify-content-between">
                  <li className="d-flex align-items-center">
                    <h6 className="text-white text-uppercase mb-0 explore-heading-two h5">
                      Customer review
                    </h6>
                  </li>
                  <li className="star-group star-group-outer d-flex align-items-center gap-3">
                    <div className="ratting-outer-box ratting-outer-box-review">
                      <span className="explore-heading-one explore-heading-one text-white d-flex align-items-center pe-2">
                        3.5
                      </span>
                      <Rating
                        size={30}
                        // onClick={(value: any) => {
                        //   checkFilter(elem, value);
                        //   setIntialVal(value);
                        // }}
                        fillColor="white"
                        emptyColor="black"
                        SVGstorkeWidth="1px"
                        SVGstrokeColor="white"
                        allowFraction
                        style={{
                          marginBottom: "0px",
                          paddingBottom: "0px",
                        }}
                        // initialValue={initialVal}
                        className="stat-rating"
                      />
                      <span className="explore-heading-one text-white d-inline text-uppercase d-flex align-items-center ps-2">
                        (18)
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <hr className="custom-white-hr w-100 mt-0" />
              <div className="w-100 d-block">
                <label className="text-white text-start explore-heading-two w-100">
                  28,172 GLOBAL RATING
                </label>
                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white  d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white  d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white  d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white  d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2 custom-progress-bar">
                  <span className="me-2 text-white  d-flex star-custom">
                    5 Star
                  </span>{" "}
                  {/* Star icon */}
                  <div className="w-100">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                  <span className="percentage percentage-custom ps-2 text-white">
                    70%
                  </span>
                </div>
              </div>
              <div className="submit-btn-custom w-100 d-flex justify-content-end">
                <button
                  className="btn book-btn book-btn-new d-block mb-0 mt-4 min-height-40"
                  data-bs-target="#specialistDetails2"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  SUBMIT
                </button>
              </div>
            </div>
            <div
              className="cancel__pop__icon"
              data-bs-target="#specialistDetails"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              <i className="fa-solid fa-xmark text-white"></i>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal  p-0 gloabl-model-settings"
        id="bookSlotPopUp"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content user_response--popup p-4 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center p-0">
              <label
                className="form-check-label mb-0 text-white explore-heading-two text-left w-100"
                htmlFor="exampleCheck1"
              >
                <span className="d-block h4 text-start mb-0">
                  SINGULAR APPOINTMENT OPTIONS
                </span>
                <span className="available-slot d-block h6 text-start">
                  AVAILABLE SLOTS FOR - DR.{" "}
                  {specialistData?.name?.toUpperCase()}
                </span>
              </label>
              <div className="book-select-options w-100 text-start">
                <div className="book-select-option-inner pb-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      onChange={() => setBookStatus("bookNow")}
                      checked={bookStatus === "bookNow"}
                    />
                    <label
                      className="form-check-label text-white explore-heading-two"
                      htmlFor="inlineRadio1"
                    >
                      <span className="ps-2"> BOOK NOW</span>
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      onChange={() => setBookStatus("bookLater")}
                      checked={bookStatus === "bookLater"}
                    />
                    <label
                      className="form-check-label text-white explore-heading-two"
                      htmlFor="inlineRadio2"
                    >
                      <span className="ps-2">BOOK LATER</span>
                    </label>
                  </div>
                  <label className="form-check-label mb-0 text-white explore-heading-two text-left w-100">
                    <span className="available-slot d-block h6 text-start text-uppercase">
                      NOTE - If you choose to book later, please ensure to
                      select a slot within the next 30 days.
                    </span>
                  </label>
                </div>
                <div id="selfDropdown">
                  <label className="available-slot d-block h6 text-start text-uppercase text-light letter_spacing">
                    SELECT TIMEZONE
                  </label>
                  <label className="available-slot d-block h6 text-start text-uppercase text-light letter_spacing">
                    NOTE - The default timezone is set according to your
                    country. If you choose a time slot based on a different
                    country's timezone, please ensure that you are present in
                    that country at the scheduled time.
                  </label>
                  <Select
                    value={selectedTimezone}
                    onChange={handleTimeZone}
                    options={mappedOptions}
                    isSearchable={true}
                    name="areaOfExp"
                    className="text-dark letter_spacing col-lg-4 mb-4 text-uppercase"
                    styles={{
                      control: (provided: any, state: any) => ({
                        ...provided,
                        fontSize: "16px",
                        color: "inherit",
                        backgroundColor: "#fff",
                        letterSpacing: "1px",
                        border: state.isFocused
                          ? "2px solid black"
                          : "2px solid transparent", // Custom border style
                        boxShadow: state.isFocused ? "none" : "none", // Remove box-shadow
                        "&:hover": {
                          borderColor: "black", // Border color on hover
                        },
                      }),
                      option: (provided: any, state: any) => ({
                        ...provided,
                        color:
                          state.isFocused || state.isSelected
                            ? "white"
                            : "black", // Change color to black when focused or selected
                        backgroundColor: state.isFocused ? "#000" : "#fff", // Background color when focused
                      }),
                      singleValue: (provided: any) => ({
                        ...provided,
                        color: "#000", // Set color of the selected option to white
                      }),
                      input: (provided: any) => ({
                        ...provided,
                        color: "black",
                      }),
                      placeholder: (provided: any) => ({
                        ...provided,
                        color: "white",
                      }),
                      menu: (provided: any) => ({
                        ...provided,
                        backgroundColor: "#000",
                      }),
                    }}
                    placeholder="SEARCH..."
                  />
                </div>
                {bookStatus === "bookNow" ? (
                  <div className="row border-top m-0 ">
                    <div className="col-md-4 border-end pt-4 pb-4 pe-lg-4 pe-md-4 pe-0 ps-0 react-calendar-outer">
                      <h6 className="mb-0 text-uppercase text-white explore-heading-two pb-2">
                        Please select the date before we proceed
                      </h6>
                      <Calendar
                        selectRange={true}
                        onChange={handleRangeSelection}
                        value={[startDate, endDate]}
                        onActiveStartDateChange={handleMonthChange} // Handle month change
                      />
                    </div>
                    <div className="col-md-8 ps-0 ps-md-4 ps-lg-4 pe-0 pt-4 pb-4">
                      <h5 className="h6 text-uppercase text-white explore-heading-two explore-heading-two-box">
                        Available time slots for the month of {selectedMonth}
                      </h5>
                      <div className="book-overflow">
                        <div className="slot-outer text-uppercase text-white">
                          <div className="slot-inner row g-3">
                            {slots.map((slot: any, index: any) => (
                              <div
                                key={index}
                                className="d-flex flex-wrap gap-4 justify-content-between mt-3 mb-3"
                              >
                                <h3 className="text-white explore-heading-two flex-100 mb-0 line-height-20 mt-3">
                                  {slot.dayOfWeek}
                                </h3>
                                {slot?.slots
                                  .sort((a: any, b: any) => {
                                    const timeA = moment(
                                      a.StartTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
                                      "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
                                    ).tz(
                                      selectedTimezone?.value || userTimezone
                                    );

                                    const timeB = moment(
                                      b.StartTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
                                      "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
                                    ).tz(
                                      selectedTimezone?.value || userTimezone
                                    );

                                    // Prioritize AM before PM
                                    if (
                                      timeA.format("A") !== timeB.format("A")
                                    ) {
                                      return timeA.format("A") === "AM"
                                        ? -1
                                        : 1;
                                    }

                                    // Sort by time within the same period
                                    return timeA.diff(timeB);
                                  })
                                  .map((elem: any) => (
                                    <div
                                      className="slot-box slot-box-new col-md-6 cursor_pointer"
                                      key={elem.uuid}
                                      onClick={() => passSlots(elem)}
                                    >
                                      <div
                                        className="slot-box-inner border pt-2 pb-2 ps-3 pe-3"
                                        style={{
                                          backgroundColor:
                                            selectedSlot === elem
                                              ? "white"
                                              : "",
                                          color:
                                            selectedSlot === elem
                                              ? "black"
                                              : "",
                                        }}
                                      >
                                        <h5 className="mb-0 explore-heading-two pb-0">
                                          AVAILABILITY | {elem?.Duration} HR
                                        </h5>
                                        <p
                                          className="mb-0 explore-heading-two pb-0"
                                          style={{
                                            color:
                                              selectedSlot === elem
                                                ? "black"
                                                : "white",
                                          }}
                                        >
                                          {selectedTimezone?.value &&
                                          elem?.StartTime
                                            ? moment
                                                .tz(
                                                  elem.StartTime.replace(
                                                    /:000Z$/,
                                                    ":00Z"
                                                  ),
                                                  selectedTimezone?.value ||
                                                    userTimezone
                                                )
                                                .format("hh:mm A")
                                            : "Date Not Available"}
                                          <span className="to"> TO </span>
                                          {selectedTimezone?.value &&
                                          elem?.EndTime
                                            ? moment
                                                .tz(
                                                  elem.EndTime.replace(
                                                    /:000Z$/,
                                                    ":00Z"
                                                  ),
                                                  selectedTimezone?.value ||
                                                    userTimezone
                                                )
                                                .format("hh:mm A")
                                            : "Date Not Available"}
                                        </p>
                                        <p
                                          className="mb-0 explore-heading-two pb-0"
                                          style={{
                                            color:
                                              selectedSlot === elem
                                                ? "black"
                                                : "white",
                                          }}
                                        >
                                          {selectedTimezone?.value &&
                                          elem?.StartTime
                                            ? moment
                                                .tz(
                                                  elem.StartTime.replace(
                                                    /:000Z$/,
                                                    ":00Z"
                                                  ),
                                                  "UTC"
                                                )
                                                .tz(
                                                  selectedTimezone?.value ||
                                                    userTimezone
                                                )
                                                .format("YYYY-MM-DD")
                                            : "Date Not Available"}
                                          <span className="day-name-right floar-right">
                                            {selectedTimezone?.value &&
                                            elem?.StartTime
                                              ? moment
                                                  .tz(
                                                    elem.StartTime.replace(
                                                      /:000Z$/,
                                                      ":00Z"
                                                    ),
                                                    "UTC"
                                                  )
                                                  .tz(
                                                    selectedTimezone?.value ||
                                                      userTimezone
                                                  )
                                                  .format("dddd")
                                              : "Date Not Available"}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            ))}
                          </div>
                        </div>

                        <hr className="custom-hr custom-hr-white text-white"></hr>
                        {slots.length === 0 && (
                          <h4 className="text-white text-center letter_spacing text-uppercase">
                            No slots available for selected date{" "}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className="cancel__pop__icon"
              data-bs-target="#specialistDetails"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              <i className="fa-solid fa-xmark text-white"></i>
            </div>

            {/* <button
              className="btn btn-primary btn-sm"
              data-bs-target="#specialistDetails"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Back to first
            </button> */}
            <div className="submit-btn-custom w-100 d-flex justify-content-end">
              <button
                className="btn book-btn book-btn-new d-block mb-0 mt-0 min-height-40"
                type="button"
                onClick={createCart}
              >
                SUBMIT
              </button>
              <input
                data-bs-target="#specialistDetails"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                id="dismissModal"
                type="hidden"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookSpecialist;
